






















import Vue from 'vue';
import './scss/PopupFilePreview.scss';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';

const NSProject = namespace('storeProject');

@Component({
    name: 'PopupFilePreview',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        FilePreview: () => import('@components/FilePreview/FilePreview.vue'),
    }
})

export default class PopupFilePreview extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    format:string = '';
    fileTitle:string = '';
    urlFileView:string = '';
    urlFileDownload:string = '';
    signed:boolean = false;

    get selectedFileIsPDFAndSigned() {
        return this.signed && this.format === 'pdf';
    }

    setData(data) {
        this.format = data.format;
        this.signed = data.signed;
        this.fileTitle = data.fileTitle;
        this.urlFileView = data.urlFileView;
        this.urlFileDownload = data.urlFileDownload;
    }

    resetData() {
        this.fileTitle = '';
        this.urlFileView = '';
        this.urlFileDownload = '';
    }

    openPopup() {
        this.$refs.basePopup.openPopup();
    }

    closePopup() {
        this.resetData();
    }
}
